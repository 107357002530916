<template>
  <base-section id="affiliates" class="grey lighten-4" space="36">
    <v-container>
      <base-section-heading title="Foire aux questions" />

      <v-layout child-flex>
        <v-expansion-panels>
          <v-expansion-panel v-for="item in data" :key="item._id">
            <v-expansion-panel-header>
              <h4 v-text="item.defect"></h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item v-for="(par, idx) in item.items" :key="idx">
                <v-list-item-content>
                  <v-list-item-title>{{ par }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-layout>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionAffiliates",
  components: {},

  props: {
    data: Array,
  },

  methods: {
    onCategorySelect(args) {
      console.log("onCategorySelect", args);
    },
    onItemSelect(args) {
      console.log("onItemSelect", args);
    }
  }
};
</script>
